// jQuery
(function($){

    var $window = $(window);
    var $document = $(document);

    var SAC = {

        filtered_doctors: '',
        page_nav_offset: 0,

        app: {

            // Scroll to content when URL contains # with offset
            hash_scroll: function(){

                if( window.location.hash ){
                    var $section = $(window.location.hash);
                    if( $section.length ){
                        var offset = 40;
                        var scroll_to = $section.offset().top;
                        if( $('.page-nav').length ){
                            offset = offset + $('.page-nav').outerHeight();
                        }
                        $('html,body').stop().animate({ scrollTop: scroll_to - offset }, 700, 'easeInOutExpo');
                    }
                }

            },

            // dropkick - https://github.com/Robdel12/DropKick
            selects: function(){

                // // blog filters
                // $('.js-blog-filter').dropkick({
                //     mobile: true,
                //     change: function(){
                //         var val = this.value;
                //         window.location = val;
                //     }
                // });

                // // form select
                // $('.js-form-select').dropkick({
                //     mobile: true,
                //     change: function(){

                //     }
                // });

            },

            // carousels - https://github.com/OwlCarousel2/OwlCarousel2
            carousels: function(){

                /*
                var nav_arrows = [
                    '<svg class="icon icon-chevron-left"><use xlink:href="'+globals.assets_url+'/images/icons.svg#icon-chevron-left"></use></svg>',
                    '<svg class="icon icon-chevron-right"><use xlink:href="'+globals.assets_url+'/images/icons.svg#icon-chevron-right"></use></svg>'
                ];
                */

                if (typeof owlCarousel === 'function'){
                    $('.js-images-carousel').owlCarousel({
                        loop: true,
                        dots: true,
                        autoplay: true,//true
                        smartSpeed: 700,
                        autoplayTimeout: 5000,//5000
                        nav: false,
                        // autoWidth: false,
                        responsiveClass: true,
                        responsive: {
                            0 : {
                                items: 2,
                                loop: true
                            },
                            480 : {
                                items: 2,
                                loop: true
                            },
                            768 : {
                                // items: Math.round(parseInt($(this).attr('data-itemsperpage'))/3)
                                items: 8,
                                loop: true
                            },
                            // 1024 : {
                            //     items: Math.round(parseInt($(this).attr('data-itemsperpage'))/2)
                            // },
                            // 1200 : {
                            //     items: Math.round(parseInt($(this).attr('data-itemsperpage'))/2)
                            // },
                            1400 : {
                                // items: $(this).attr('data-itemsperpage'),
                                items: 8,
                                loop: true
                            },
                        }
                    });
    
                    $('.js-images-carousel-navbuttons').owlCarousel({
                      loop: true,
                      dots: true,
                      autoplay: true,//true
                      smartSpeed: 700,
                      autoplayTimeout: 5000,//5000
                      nav: true,
                      navText: [$('.news-carousel__arrow-left'), $('.news-carousel__arrow-right')],
                      // autoWidth: false,
                      responsiveClass: true,
                      responsive: {
                          0 : {
                              items: 1,//2
                              loop: true
                          },
                          480 : {
                              items: 1,//2
                              loop: true
                          },
                          768 : {
                              // items: Math.round(parseInt($(this).attr('data-itemsperpage'))/3)
                              items: 1,//4
                              loop: true
                          },
                          // 1024 : {
                          //     items: Math.round(parseInt($(this).attr('data-itemsperpage'))/2)
                          // },
                          // 1200 : {
                          //     items: Math.round(parseInt($(this).attr('data-itemsperpage'))/2)
                          // },
                          1400 : {
                              // items: $(this).attr('data-itemsperpage'),
                              items: 1,
                              loop: false
                          },
                      }
                    });                
    
                    $('.js-images-carousel-caption').owlCarousel({
                        loop: true,
                        dots: true,
                        autoplay: false,
                        smartSpeed: 700,
                        autoplayTimeout: 5000,
                        nav: true,
                        items: 1
                    });

                }

            },


            events: {

                global: function(){


                },

                // User input events such as clicks and key presses
                input: function(){

                    // Keyboard events
                    $document.on('keyup', function(e){

                        // Escape key
                        if (e.keyCode === 27) {
                            $('.js-search-overlay').removeClass('is-active');
                            $('.js-nav-mobile').removeClass('is-active');
                        }

                    });

                    // Mobile menu
                    $(document).on('click', '.js-menu-toggle', function(e){
                        $('.js-hamburger').toggleClass('is-active');
                        $('.js-nav-mobile').toggleClass('is-active');
                        e.preventDefault();
                    });

                    // Back to top
                    $(document).on('click', '.js-back-to-top', function(e){
                        $('html,body').stop().animate({ scrollTop: 0 }, 0, 'easeInOutQuad');
                        e.preventDefault();
                    });

                    // Show sub menu
                    // $(document).on('click', '.menu-primary-container .menu-item-has-children > a', function(e){
                    //     $('.menu-item').not($(this).parent('.sub-menu')).removeClass('is-active');
                    //     $(this).parent('.menu-item').toggleClass('is-active');
                    //     e.stopPropagation();
                    //     e.preventDefault();
                    // });

                    // Close sub menu
                    $(document).on('click', function(){
                        $('.menu-item').removeClass('is-active');
                    });

                    // Show mobile sub menu
                    $(document).on('click', '.nav-mobile .menu-item-has-children > a', function(e){
                        $(this).siblings('.sub-menu').addClass('is-active');
                        $('.nav-mobile-underlay').addClass('is-active');
                        e.stopPropagation();
                        e.preventDefault();
                    });

                    // Hide mobile sub menu
                    $(document).on('click', '.nav-mobile-underlay', function(e){
                        $('.nav-mobile .sub-menu').removeClass('is-active');
                        $('.nav-mobile-underlay').removeClass('is-active');
                        e.preventDefault();
                    });

                    // // Show search overlay
                    // $(document).on('click', '.js-search-toggle', function(e){
                    //     $('.js-search-overlay').addClass('is-active');
                    //     $('.js-search-overlay [name="search"]').focus();
                    //     e.preventDefault();
                    // });

                    // // Close search overlay
                    // $(document).on('click', '.js-close-search', function(e){
                    //     $('.js-search-overlay').removeClass('is-active');
                    //     e.preventDefault();
                    // });


                    // Show faq item
                    $(document).on('click', '.js-faq-question', function(e){
                      if ($(this).parent().hasClass('is-active')) {
                        $(this).parent().removeClass('is-active');
                        $(this).find('.icon').eq(0).removeClass('is-active');
                      }else{
                        $(this).parent().addClass('is-active');
                        $(this).find('.icon').eq(0).addClass('is-active');
                      }
                    });


                    // $('.popup-link').magnificPopup({
                    //   type: 'image'
                    //   // other options
                    // });

                    // Tabular Content
                    // $(document).on('click', '.tab_menu_item', function(e){
                    //   var tab_id = $(this).attr('data-tab');
                    //   $('.tab_menu_item').removeClass('current');
                    //   $(this).addClass('current');
                    //   $('.tab_content_item').removeClass('current');
                    //   $('#'+tab_id).addClass('current');
                    // });

                    // // Form input
                    // $(document).on('focus', '.field-input', function(){
                    //     $(this).parents('.field-group').addClass('is-active');
                    // });

                    // $(document).on('focusout', '.field-input', function(){
                    //     var $this = $(this);
                    //     if( !$this.val() ){
                    //         $(this).parents('.field-group').removeClass('is-active');
                    //     }
                    // });

                    // // Accordion
                    // $(document).on('click', '.js-faq-question', function(){
                    //     $(this).parent().toggleClass('is-active');
                    // });

                    // // Checklist
                    // $(document).on('click', '.js-checklist', function(){
                    //     $(this).toggleClass('is-active');
                    // });

                    // var box = $('section.read-more-enabled');
                    // var max = box.offset().top + box.outerHeight();
                    // var min = max - 32;
                    // var checkRange = function(y) {
                    //   return (y>min && y <= max);
                    // }
                    // box.click(function(e){
                    //   if (checkRange(e.pageY)) {
                    //     box.toggleClass('read-more-expanded');
                    //   }
                    // });
                    // box.append('<div class="btn_read-more-toggle"></div>').click(function(e){
                    //   $(this).toggleClass('read-more-expanded');
                    // });

                }

            },

            polyfills: function(){

                // remove click delay on mobile devices - https://github.com/ftlabs/fastclick
                // FastClick.attach(document.body);

                // svg - https://github.com/jonathantneal/svg4everybody
                // svg4everybody();

                // object fit for images - http://bfred-it.github.io/object-fit-images
                // window.objectFitImages();

            },

            // Runs when DOM is ready
          ready: function(){

            // polyfills
                SAC.app.polyfills();

                // input events
                SAC.app.events.input();

                // global events
                SAC.app.events.global();

                // hash scroll
                SAC.app.hash_scroll();

                // // load carousels
                // SAC.app.carousels();

                // custom select dropdowns
                // SAC.app.selects();

                // search form
                // SAC.app.search_form();

                // $('p:empty').remove();


          },

          // Runs when page has fully loaded
            load: function(){
                // // load carousels
                SAC.app.carousels();

                $('p:empty').remove();

                // var hash = document.location.hash;
                // if (hash) {
                //   if ($('.render-torsel-products').length > 0){
                //     $('.tab_menu_item[data-tab="'+hash.replace('#','')+'"]').trigger('click');
                //   }
                //   var target = $('.tabular-content-module');
                //   if (target.length) {
                //     var top = target.offset().top;
                //     $('html,body').animate({scrollTop: top}, 1000);
                //     return false;
                //   }
                // }

                // if (!Modernizr.backgroundblendmode) {
                //   $('.page-intro-background.page-intro-overlay').addClass('no-backgroundblendmode');
                // }

            },

            // Runs while scrolling
          scroll: function(){

                var scroll_pos = $window.scrollTop();

                // page nav
                // SAC.app.page_nav.do(scroll_pos);
                //
                if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
                    document.querySelector('.js-back-to-top').style.opacity='0.5';
                    // document.getElementById("myBtn").style.display = "block";
                } else {
                    document.querySelector('.js-back-to-top').style.opacity='0';
                    // document.getElementById("myBtn").style.display = "none";
                }

                // $("#sticky").css({
                //   "top": "0",
                //   "position": $(this).scrollTop() > 140 ? "fixed" : "relative"
                // });

          },

            // Runs when browser is resized
          resize: function(){

            // var owl = $('.js-feed-testimonial').data('owl.carousel');
            // owl.onResize();
            // $('.owl-carousel').owlCarousel('destroy');
            // SAC.app.carousels();
          }

        }

    };

    $(function(){
      SAC.app.ready();
        console.time('Load');
    });

    $window.on('load', function(){
      SAC.app.load();
        console.timeEnd('Load');
    });

    $window.on('scroll', function(){
      SAC.app.scroll();
    });

    var resize;

    $window.on('resize', function(){
      clearTimeout(resize);
      resize = setTimeout(function(){
        SAC.app.resize();
      }, 70);
    });

})(jQuery);
