// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;


var navbarHeight = jQuery('header').outerHeight();

var pageIntroHeight = jQuery('.page-intro').outerHeight();
if (jQuery('.post-intro').length > 0){
    pageIntroHeight = jQuery('.post-intro').outerHeight();
}

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {

    var st = jQuery(document).scrollTop();

    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        jQuery('header').removeClass('nav-down').addClass('nav-up');
        jQuery('body').removeClass('nav-down').addClass('nav-up');

    } else {
        // Scroll Up
        if(st + $(window).height() < jQuery(document).height()) {
            jQuery('header').removeClass('nav-up').addClass('nav-down');
            jQuery('body').removeClass('nav-up').addClass('nav-down');
        }
    }
    if (st >= pageIntroHeight){
        // jQuery('header').addClass('nav-contrast');
        // jQuery('body').addClass('nav-contrast');
    }else{
        // jQuery('header').removeClass('nav-contrast');
        // jQuery('body').removeClass('nav-contrast');
    }

    lastScrollTop = st;
}
